<template>
  <el-form
    :ref="finalConfig.ref"
    :inline="finalConfig.inline"
    :size="finalConfig.size"
    :label-width="finalConfig.labelWidth"
    :model="model"
    :rules="rules"
  >
    <el-form-item
      v-for="(item, index) in formItems"
      :key="`formItems-${index}`"
      :label="item.label"
      :prop="item.prop || item.name"
    >
      <template v-if="item.type === 'daterange'">
        <el-col :span="11">
          <el-date-picker
            :placeholder="
              item.starPlaceholder ? item.starPlaceholder : '请选择'
            "
            v-model="model[item.starName]"
            :picker-options="mapOfDaterangePickerOptions[item.starName]"
            type="date"
            value-format="yyyy-MM-dd"
            :disabled="item.disabled"
          ></el-date-picker>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-date-picker
            :placeholder="item.endPlaceholder ? item.endPlaceholder : '请选择'"
            v-model="model[item.endName]"
            :picker-options="mapOfDaterangePickerOptions[item.endName]"
            type="date"
            value-format="yyyy-MM-dd"
            :disabled="item.disabled"
          ></el-date-picker>
        </el-col>
      </template>

      <template v-else-if="item.type === 'select'">
        <el-select
          v-model="model[item.name]"
          :placeholder="item.placeholder ? item.placeholder : '请选择'"
          filterable
          clearable
          :disabled="item.disabled"
          @change="item.change ? item.change() : null"
        >
          <el-option
            v-for="(option, optionIndex) in item.options"
            :key="`formItems-${index}-${item.name}-${optionIndex}`"
            :label="option[item.optionLabel] || option.name || option"
            :value="option[item.optionValue] || option.value || option"
          ></el-option>
        </el-select>
      </template>

      <template v-else-if="item.type === 'select-product-category'">
        <el-select
          v-model="model[item.name]"
          :placeholder="item.placeholder ? item.placeholder : '请选择'"
          filterable
          clearable
          :disabled="item.disabled"
          :loading="productCategoryLoading"
          @change="value => change('product-category', value)"
        >
          <el-option
            v-for="(option, optionIndex) in productCategoryList"
            :key="`formItems-${index}-${item.name}-${optionIndex}`"
            :label="option.cateName"
            :value="option.id"
          ></el-option>
        </el-select>
      </template>

      <template v-else-if="item.type === 'select-brand'">
        <el-select
          v-model="model[item.name]"
          :placeholder="item.placeholder ? item.placeholder : '请选择'"
          filterable
          clearable
          :disabled="item.disabled"
          :loading="brandLoading"
        >
          <el-option
            v-for="(option, optionIndex) in brands"
            :key="`formItems-${index}-${item.name}-${optionIndex}`"
            :label="option.cateName"
            :value="option.id"
          ></el-option>
        </el-select>
      </template>

      <template v-else-if="item.type === 'select-province-city'">
        <el-cascader
          v-model="model[item.name]"
          :options="item.options"
          :props="{
            value: 'label',
          }"
        >
        </el-cascader>
      </template>

      <template v-else>
        <el-input
          v-model="model[item.name]"
          :placeholder="item.placeholder ? item.placeholder : '请输入'"
          :disabled="item.disabled"
        ></el-input>
      </template>
    </el-form-item>
  </el-form>
</template>

<script>
import * as dayjs from 'dayjs';
import appApi from '../api/app';

export default {
  name: 'DynamicForm',
  props: {
    config: {
      type: Object,
      default: () => ({
        ref: 'form',
        inline: true,
        size: 'small',
        labelWidth: '130px',
      }),
    },
    formItems: {
      type: Array,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      initConfig: {
        ref: 'form',
        inline: true,
        size: 'small',
        labelWidth: '130px',
      },
      productCategoryLoading: false,
      productCategoryList: [],
      brandLoading: false,
      brands: [],
      provinceCityText: [],
    };
  },
  watch: {},
  computed: {
    finalConfig() {
      const config = {
        ...this.initConfig,
        ...this.config,
      };
      return config;
    },
    dateranges() {
      return this.formItems.filter(item => item.type === 'daterange');
    },
    mapOfDaterangePickerOptions() {
      const map = {};
      this.dateranges.forEach(item => {
        const { starName, endName } = item;
        map[starName] = {
          disabledDate: time => {
            let disabled = false;
            if (endName && this.model[endName]) {
              const compareTime = this.model[endName];
              disabled = time.getTime() >= dayjs(compareTime);
            }
            return disabled;
          },
        };
        map[endName] = {
          disabledDate: time => {
            let disabled = false;
            if (starName && this.model[starName]) {
              const compareTime = this.model[starName];
              disabled = time.getTime() <= dayjs(compareTime);
            }
            return disabled;
          },
        };
      });

      return map;
    },
  },
  created() {
    this.init();
  },
  methods: {
    clear() {
      this.$emit('update:model', {});
    },
    reset() {
      this.$refs[this.finalConfig.ref].resetFields();
    },
    init() {
      const { formItems } = this;
      const point = formItems.find(
        ({ type }) => type === 'select-product-category',
      );
      if (point) {
        this.getCategoryFirst();
      }
    },
    getCategoryFirst() {
      this.productCategoryLoading = true;
      const promise = new Promise((resolve, reject) => {
        appApi
          .first()
          .then(res => {
            this.productCategoryList = res.result;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.productCategoryLoading = false;
          });
      });
      return promise;
    },
    getCategoryBrand() {
      this.brandLoading = true;
      this.brands = [];
      const promise = new Promise((resolve, reject) => {
        const { firstCateId } = this.model;
        const selected = this.productCategoryList.find(
          ({ id }) => id === firstCateId,
        );
        console.log(selected);
        const ext = {
          cateLevel: 2,
          parentCateId: firstCateId,
        };
        appApi
          .brand(ext)
          .then(res => {
            this.brands = res.result;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.brandLoading = false;
          });
      });
      return promise;
    },
    change(type, value) {
      switch (type) {
        case 'product-category':
          {
            const target = { ...this.model };
            target.brandCateId = '';
            this.$emit('update:model', target);
            this.brands = [];
            if (value) {
              this.getCategoryBrand();
            }
          }

          break;
        default:
          break;
      }
    },
    validate(successfulCallback, failedCallback) {
      this.$refs[this.finalConfig.ref].validate(valid => {
        if (valid) {
          if (typeof successfulCallback === 'function') {
            successfulCallback();
          }
        } else if (typeof failedCallback === 'function') {
          failedCallback(valid);
        }
      });
    },
  },
};
</script>

<style lang="scss">
</style>
