<template>
  <div>
    <l-toggle>
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <search-reset
        :loading="loading"
        :model.sync="model"
        @search="reqTableData"
      ></search-reset>
    </l-toggle>
    <div class="tableBox">
      <el-table
        ref="eltable"
        :data="tableData"
        :header-cell-style="{background:'#f2f2f2'}"
        height="100%"
        stripe
        border
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <img src="@/assets/img/table_noData.png" width="300" height="300" />
        </template>
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="id" label="客户ID" align="center" width="100"></el-table-column>
        <el-table-column prop="phone" label="手机号码" align="center" width="200"></el-table-column>
        <el-table-column prop="realName" label="客户姓名" align="center"></el-table-column>
        <el-table-column prop="identNo" label="身份证号" align="center" width="200"></el-table-column>
        <el-table-column prop="regTime" label="注册时间" align="center" width="150"></el-table-column>
        <el-table-column label="是否绑定手机" align="center" width="120">
          <template slot-scope="scope">
              <p v-if="scope.row.phone">是</p>
              <p v-else>否</p>
          </template>
        </el-table-column>
        <el-table-column label="是否实名认证" align="center" width="120">
          <template slot-scope="scope">
              <p v-if="scope.row.realName">是</p>
              <p v-else>否</p>
          </template>
        </el-table-column>
        <el-table-column label="是否删除" align="center" width="100">
          <template slot-scope="scope">
              <p v-if="isDel(scope.row.isDel)">是</p>
              <p v-else>否</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              size="mini"
              @click="view(scope.row)"
            >查看
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="del(scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      style="margin-top: 10px;"
      background
      :disabled="loading"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.pageNum"
      :page-sizes="[30, 50, 100]"
      :page-size="30"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total"
    >
    </el-pagination>

    <!-- 新增层 -->
    <user-detail
      ref="UserDetail"
      @refreshTable="refreshTable"
    ></user-detail>
  </div>
</template>

<script>
import LToggle from '@/components/LToggle.vue';
import UserDetail from './UserList/UserDetail.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import SearchReset from '@/components/SearchReset.vue';
import api from '@/api/UserListApi';

export default {
  name: 'UserList',
  components: {
    LToggle,
    DynamicForm,
    SearchReset,
    UserDetail,
  },
  data() {
    return {
      model: {},
      formItems: [
        {
          label: '客户姓名',
          type: 'text',
          name: 'realName',
          placeholder: '请输入客户姓名',
        },
        {
          label: '客户联系方式',
          type: 'text',
          name: 'phone',
          placeholder: '请输入客户联系方式',
        },
        {
          label: '身份证号',
          type: 'text',
          name: 'identNo',
          placeholder: '请输入客户联系方式',
        },
        {
          label: '注册时间',
          type: 'daterange',
          starName: 'regTimeStart',
          starPlaceholder: '请输入开始日期',
          endName: 'regTimeEnd',
          endPlaceholder: '请输入结束日期',
        },
      ],
      loading: false,
      tableData: [],
      selectedTableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 30,
        total: 0,
      },
    };
  },
  created() {
    this.reqTableData();
  },
  methods: {
    isDel(del) {
      if (del === '1') {
        return true;
      }
      return false;
    },
    searchEvent() {
      this.reqTableData();
    },
    refreshTable() {
      this.pageInfo.pageNum = 1;
      this.reqTableData();
    },
    reqTableData() {
      this.loading = true;
      const { model } = this;
      console.log(model);
      const {
        pageNum,
        pageSize,
      } = this.pageInfo;
      const ext = {
        pageNum,
        pageSize,
        ...model,
      };
      console.log(ext);
      api.getUserInfoList(ext)
        .then(res => {
          this.tableData = res.result.datas;
          this.pageInfo.total = res.result.totalRecords;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    view(row) {
      this.$refs.UserDetail.dialogVisible = true;
      this.$refs.UserDetail.dialogTitle = '查看用户详情';
      this.$nextTick(() => {
        this.$refs.UserDetail.viewDetail(row.id);
      });
    },
    del(row) {
      this.$confirm('确定要<span class="dangerColor">删除</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在删除';
            const { id } = row;
            api.deleteUser({
              id,
            })
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('删除成功!');
                this.refreshTable();
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      }).then(() => {}).catch(() => {});
    },
    handleSelectionChange(select) {
      this.selectedTableData = select;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.reqTableData();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.reqTableData();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
