<template>
  <div class="buttons">
    <el-button
      type="primary"
      size="small"
      icon="el-icon-search"
      :loading="loading"
      @click="search"
    >
      搜索
    </el-button>
    <el-button
      size="small"
      plain
      :disabled="loading"
      icon="el-icon-refresh"
      @click="reset"
    >
      重置
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'SearchReset',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    reset() {
      this.$emit('update:model', {});
    },
    search() {
      this.$emit('search');
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: flex-end;
}
</style>
