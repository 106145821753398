import { postInfo } from './api';

export default {
  // 获取用户列表
  getUserInfoList(data) {
    return postInfo('/user/getUserInfoList', data);
  },
  // 删除用户
  deleteUser(data) {
    return postInfo('/user/delete', data);
  },
  // 用户明细
  detailUser(data) {
    return postInfo('/user/detail', data);
  },
  // 获取用户申请列表
  getUserApplyList(data) {
    return postInfo('/user/getUserApplyList', data);
  },
};
