<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="800px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="userDetailData"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="手机号"
              prop="phone"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.phone"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="姓名"
              prop="realName"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.realName"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="身份证"
              prop="identNo"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.identNo"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="微信昵称"
              prop="nikeName"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.nikeName"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="微信地区"
              prop="area"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.area"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="性别"
              prop="gender"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.gender"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="银行名称"
              prop="bankName"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.bankName"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="银行账号"
              prop="bankCard"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.bankCard"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="分支行名称"
              prop="bankBranchName"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.bankBranchName"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="是否删除"
              prop="isDel"
            >
              <el-input
                disabled
                v-model.trim="userDetailData.isDel"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="dialogVisible = false"
          :loading="loading"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/UserListApi';

export default {
  name: 'UserDetail',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '查看用户详情',
      loading: false,
      userDetailData: {
        phone: '',
        realName: '',
        identNo: '',
        nikeName: '',
        area: '',
        gender: '',
        bankName: '',
        bankCard: '',
        bankBranchName: '',
        isDel: '',
      },
    };
  },
  methods: {
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
    viewDetail(id) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        body: true,
      });
      api.detailUser(
        { id },
      )
        .then(res => {
          this.userDetailData = res.result;
        })
        .catch(err => {
          this.$message.error(err.message);
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
